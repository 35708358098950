import {Game} from "./types";
import {PathProps} from "../index";
import {App} from "./App";
import {GameFragment} from "./games/GameFragment";
import {$KTM, KeyMetadatas} from "../shared/types";

enum GameType {
  GHOST_RUN = "ghostrun",
  DONUT_CLICKER = "donutclicker",
  JUMP_DUCK = "jumpduck",
  SCOOT_SHOOT = "scootshoot",
  LEMONATOR = "lemonator",
  POTTER_QUIZ = "potterquiz",
  KITTY_DRESSUP = "kittydressup",
  PING_PONG = "pingpong",
  COOKIE_MAKER = "cookiemaker",
  FLYING_DODGE = "flyingdodge",
  CHASE = "chase",
  FLAPIT_BIRD = "flapit_bird",
  SPACE_DODGE = "space_dodge",
}

type GameMetadata = {
  name: string,
  scratchProjectId: string,
}

const GAME_TYPE_KTM = new KeyMetadatas<GameMetadata>([
  $KTM(GameType.GHOST_RUN, {name: "Ghost Run", scratchProjectId: "1077525417"}),
  $KTM(GameType.DONUT_CLICKER, {name: "DONUT Clicker", scratchProjectId: "1077530236"}),
  $KTM(GameType.JUMP_DUCK, {name: "Jump Duck", scratchProjectId: "1078115688"}),
  $KTM(GameType.SCOOT_SHOOT, {name: "Scoot'N'Shoot", scratchProjectId: "1078396002"}),
  $KTM(GameType.LEMONATOR, {name: "Lemonator", scratchProjectId: "1079312484"}),
  $KTM(GameType.POTTER_QUIZ, {name: "Potter Quiz", scratchProjectId: "1080543668"}),
  $KTM(GameType.KITTY_DRESSUP, {name: "Kitty Dress Up", scratchProjectId: "1078733822"}),
  $KTM(GameType.PING_PONG, {name: "Ping Pong", scratchProjectId: "1080795176"}),
  $KTM(GameType.COOKIE_MAKER, {name: "Cookie Maker", scratchProjectId: "1090324560"}),
  $KTM(GameType.FLYING_DODGE, {name: "Flying Dodge", scratchProjectId: "1080867312"}),
  $KTM(GameType.CHASE, {name: "Chase", scratchProjectId: "1086653420"}),
  $KTM(GameType.FLAPIT_BIRD, {name: "Flap'it Bird", scratchProjectId: "1114246646"}),
  $KTM(GameType.SPACE_DODGE, {name: "Space Dodge", scratchProjectId: "1114601561"}),
]);

export const GAMES: Game[] = Object.getOwnPropertyNames(GameType).map(type => {
  const metadata = GAME_TYPE_KTM.findMetadata(GameType[type]);
  const game: Game = {
    id: GameType[type],
    scratchProjectId: metadata.scratchProjectId,
    name: metadata.name,
    icon: "/games/" + GameType[type] + "/icon.png",
    show: (path: PathProps) => App.CONTEXT.showFullscreenDialog(() => <GameFragment path={path} game={game}/>),
  };
  return game;
})